.nav-trigger-box{
    padding: 6px 0 0;

    .is-open-left &{
        position: fixed;
    }

    @include breakpoint(medium) {
        padding: 12px 0 0;
    }
}

.nav-trigger{
    width: 25px;
    height: 23px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    &:hover{

        span{
            background: smart-scale($primary-color, 20%);
        }
    }

    span{
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $primary-color;
        border-radius: 2px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2), &:nth-child(3) {
            top: 10px;
        }

        &:nth-child(4) {
            top: 21px;
        }

    }

    .is-open-left &{

        span{

            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}
