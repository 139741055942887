#modal-save{
    max-width: 536px;
}

#modal-message{
    max-width: 1091px;
}

#modal-thermoshield{
    max-width: 1169px;

    .reveal-content{
        background: $light-gray;
        padding: 10px 15px 10px;
    }
}
[id^="modal-usp-"]{
    max-width: 1169px;

    .reveal-table-head{
        padding: 2px 0px 2px 10px;
    }

    .reveal-table-row{
        padding: 0 9px 0 20px;
    }

    .reveal-content{
        background: $light-gray;
        padding: 10px 15px 10px;
    }
}

.is-reveal-open{
    .off-canvas-wrapper{
        overflow-y: scroll;
    }
}

.reveal-title{
    color: $white;
    font-family: $oswald-regular;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1.7px;
    font-weight: 800;
    margin: 0 0 16px;
}

.reveal-content{
    background: $white;
    border-radius: 2px;
    padding: 15px;
}

.reveal-info{
    padding: 14px 0 1px;
    margin: 0 0 16px;
    p{
        color: $black;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.4px;
    }
}

.reveal-buttons{
    margin: 0 0 10px;

    .button{
        padding: 0.45em 34px;
        margin: 0 10px;
    }
}

.reveal-editor{
    padding: 7px 0 0;
    margin: 0 0 21px;

    > .grid-x{
        > .cell{
            &:nth-child(1){
                @include xy-cell(19.6%);
            }
            &:nth-child(2){
                @include xy-cell(48.1%);
            }
        }
    }
}

.reveal-header{
    margin: 0 0 4px;
    .button{
        margin: 0;
    }
}

.reveal-table-head{
    padding: 2px 13px 2px 22px;
}

.reveal-table-head-title{
    color: $black;
    font-family: $oswald-regular;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    display: inline-block;
    padding: 4px 0 0;
}

.reveal-table-row{
    background: $white;
    margin: 0 0 7px;
    padding: 0 34px 0 20px;

    > .grid-x{
        min-height: 40px;
    }
}

.reveal-table-body-title{
    font-family: $oswald-regular;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.7px;
}
