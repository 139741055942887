.row-fiter{
    position: relative;
    margin: 0 0 10px;
    padding: 0 6px 0 4px;
    color: $medium-gray;
    text-transform: uppercase;
    font-family: $oswald-regular;

    &.row-fiter-management{
        padding: 0 37px 0 10px;
        margin: 0 0 4px 0;
    }

    &.row-fiter-medias{
        margin: 0 -6px 5px 0;
        padding: 0 0 0 4px;
    }

    &.row-fiter-right-stick{
        padding-right: 0;
    }

    &.row-filter-l-size{
        padding: 0 6px 0 20px;
    }

    &.row-filter-m-size{
        padding: 0 10px;
    }

    &.row-fiter-articles{
        padding: 0 30px 0 24px;
    }
}

.row-fiter-item{

    > span{
        display: inline-block;
    }
}

.row-move-nav{
    position: relative;
    display: inline-block;
    width: 5px;

    .row-fiter-item &{
        margin: 0 0 0 5px;
    }

    .accordion-management &{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 7px;
    }
}

.row-move-up, .row-move-down{
    color: $primary-color;
    font-size: 5px;
    position: relative;
    top: 1px;
    cursor: pointer;
    transition: color $global-transition;

    &:hover{
        color: smart-scale($primary-color, 50%);
    }
}

.row-move-up{
    margin: 0 0 2px;
    transform: rotate(180deg);
}


.add-new-user{

    &:hover{
        opacity: 0.5;
    }
}

.row-list-item-status{
    display: inline-block;
    border: 2px solid $black;
    border-radius: 50%;
    font-size: 7px;
    padding: 2px 0 0;
    width: 15px;
    height: 15px;
    text-align: center;
}

.row-list-item-status-no{

    &::before{
        position: relative;
        top: -3px;
        content: "";
        display: inline-block;
        width: 11px;
        height: 3px;
        background: $black;
    }
}

.row-list-item{
    padding: 8px 5px;
    margin: 0 0 8px;
    font-family: $open-sans-semibold;
    background: $white;
    border-radius: 2px;

    .row-list.row-list-l-size &{
        padding: 10px 6px 10px 20px;
    }

    .row-list.row-list-variant-size &{
        padding: 10px 5px;

        .row-list-item-status{
            margin: 0 0 0 6px;
        }
    }

    &.row-list-item-bordered{
        box-shadow: 0 0 0 1px $light-gray inset;
    }

    .row-list-thin &{
        font-family: $open-sans-light;
    }
}

.row-list-grid{

    > .cell{

        &:nth-child(1){
            @include xy-cell(15.5%);
        }

        &:nth-child(2){
            @include xy-cell(47.8%);
        }

        &:nth-child(3){
            @include xy-cell(26.7%);
        }

        &:nth-child(4){
            @include xy-cell(10%);
        }
    }

    &.row-list-grid-management{

            > .cell{

            &:nth-child(1){
                padding: 0 0 0 6px;
                @include xy-cell(25.7%);
            }

            &:nth-child(2){
                @include xy-cell(74.2%);
            }
        }
    }

    &.row-list-grid-5{

        > .cell{

            &:nth-child(1){
                @include xy-cell(8.6%);
            }

            &:nth-child(2){
                @include xy-cell(33.9%);
            }

            &:nth-child(3){
                @include xy-cell(30.4%);
            }

            &:nth-child(4){
                @include xy-cell(22.1%);
            }

            &:nth-child(5){
                @include xy-cell(5%);
            }
        }
    }

    &.row-list-grid-8{

        > .cell{

            &:nth-child(1){
                @include xy-cell(8.5%);
            }

            &:nth-child(2){
                @include xy-cell(14%);
            }

            &:nth-child(3){
                @include xy-cell(14.6%);
            }

            &:nth-child(4){
                @include xy-cell(14.4%);
            }

            &:nth-child(5){
                @include xy-cell(11.7%);
            }

            &:nth-child(6){
                @include xy-cell(14.6%);
            }

            &:nth-child(7){
                @include xy-cell(13.6%);
            }

            &:nth-child(8){
                @include xy-cell(8.6%);
            }

        }
    }

    &.row-list-grid-4-custom{

        > .cell{

            &:nth-child(1){
                @include xy-cell(22.1%);
            }

            &:nth-child(2){
                @include xy-cell(27.3%);
            }

            &:nth-child(3){
                @include xy-cell(42.6%);
            }

            &:nth-child(4){
                @include xy-cell(8%);
            }
        }
    }


    &.row-list-grid-news{

        > .cell{

            &:nth-child(1){
                @include xy-cell(8.6%);
                font-family: $oswald-regular;
                font-size: 15px;
                line-height: 1.5;
            }

            &:nth-child(2){
                @include xy-cell(15.5%);
                font-family: $oswald-regular;
                font-size: 15px;
                text-transform: uppercase;
            }

            &:nth-child(3){
                @include xy-cell(70%);
            }

            &:nth-child(4){
                @include xy-cell(8%);
            }
        }
    }

    &.row-list-grid-attr{

        > .cell{

            &:nth-child(1){
                @include xy-cell(44.6%);
            }

            &:nth-child(2){
                @include xy-cell(40%);
            }
            &:nth-child(3){
                @include xy-cell(10%);
            }
        }
    }

    &.row-list-grid-medias-head{

        > .cell{

            &:nth-child(1){
                @include xy-cell(6.6%);
            }

            &:nth-child(2){
                @include xy-cell(22.4%);
            }

            &:nth-child(3){
                @include xy-cell(20.7%);
            }

            &:nth-child(4){
                @include xy-cell(20.7%);
            }

            &:nth-child(5){
                @include xy-cell(23%);
            }

            &:nth-child(6){
                @include xy-cell(6.1%);
            }

            &:nth-child(7){
                @include xy-cell(14%);
            }

        }
    }

}

.grid-x-medias{

    .row-list-item-status{
        margin: 0 0 0 17px;
    }

    > .cell{

        &:nth-child(1){
            @include xy-cell(6.6%);
        }

        &:nth-child(2){
            @include xy-cell(11.3%);
        }

        &:nth-child(3){
            @include xy-cell(14.8%);
        }

        &:nth-child(4){
            @include xy-cell(11.3%);
        }

        &:nth-child(5){
            @include xy-cell(21.1%);
        }

        &:nth-child(6){
            @include xy-cell(20.1%);
        }

        &:nth-child(7){
            @include xy-cell(10%);
        }

        &:nth-child(8){
            @include xy-cell(4.8%);
        }

    }
}

.cell-high-text{
    font-family: $oswald-regular;
    font-size: 16px;
    text-transform: uppercase;
}

.cell-uppercase{
    text-transform: uppercase;
}


.row-list-item-digit-cell{
    font-family: $oswald-regular;
    font-size: 15px;
}

.filter-label{
    padding: 4px 0 0;

    &.filter-label-media{
        margin: 0 0 24px;
    }
}

.filter-label-grid{
    margin: 0 0 9px;

    > .cell{
        &:nth-child(1){
            @include xy-cell(12.7%);
        }
        &:nth-child(2){
            @include xy-cell(77%);
        }
    }
}

.filter-label-name{
    font-size: 14px;
    font-family: $oswald-regular;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.filter-label-item{
    .label{
        margin: 0 5px 9px 0;
    }
}

.row-list-grid-articles{

    > .cell{

        &:nth-child(1){
            @include xy-cell(5.5%);
        }

        &:nth-child(2){
            @include xy-cell(11.8%);
        }

        &:nth-child(3){
            @include xy-cell(14.9%);
        }

        &:nth-child(4){
            @include xy-cell(11.5%);
        }

        &:nth-child(5){
            @include xy-cell(22.3%);
        }

        &:nth-child(6){
            @include xy-cell(20.7%);
        }

        &:nth-child(7){
            @include xy-cell(13.2%);
        }
    }
}

.grid-x-articles{
    > .cell{

        &:nth-child(1){
            @include xy-cell(5.5%);
            padding: 0 0 0 9px;
        }

        &:nth-child(2){
            @include xy-cell(11.8%);
        }

        &:nth-child(3){
            @include xy-cell(14.9%);
        }

        &:nth-child(4){
            @include xy-cell(11.5%);
            font-family: $open-sans-semibold;
            text-transform: capitalize;
            font-size: 12px;
        }

        &:nth-child(5){
            @include xy-cell(22.3%);
            font-family: $open-sans-semibold;
            text-transform: capitalize;
            font-size: 12px;
        }

        &:nth-child(6){
            @include xy-cell(20.7%);
            font-family: $open-sans-semibold;
            text-transform: capitalize;
            font-size: 12px;
        }

        &:nth-child(7){
            @include xy-cell(13.2%);
        }
    }
}

.accordion-articles{

    .accordion-item{
        margin: 0 0 5px;
    }

    .accordion-title{
        padding: 7px 30px 7px 24px;
    }

    .row-move-nav{
        @include vertical-center;
        left: 9px;
    }
}

.row-fiter-overview{
    padding: 0 30px 0 16px;
    margin: 0 0 4px;
}

.row-fiter-all-label{
    text-transform: none;
}

.row-list-grid-overview{
    > .cell{

        &:nth-child(1){
            @include xy-cell(86%);
        }

        &:nth-child(2){
            @include xy-cell(14%);
        }
    }

    label{
        text-transform: initial;
        line-height: 1.4;
        font-family: $open-sans-regular;
        margin: 0 0 0 5px;
    }

    .custom-checkbox [type="checkbox"] + span{
        &:before{
            top: 1px;
        }

    }
    .custom-checkbox :checked + span:after,
    .custom-radio :checked + span:after{
        top: 3px;
    }
}

.grid-x-item-overview{
    > .cell{
        &:nth-child(1){
            @include xy-cell(86%);
        }

        &:nth-child(2){
            @include xy-cell(14%);
        }
    }
}

.accordion-overview{
    > .accordion-item{
        > .accordion-title{
            padding: 9px 50px 8px 8px;
        }
    }

    .accordion-content{
        padding: 0;
    }

    .accordion-simple-content{
        .accordion-title{
            padding: 2px 50px 4px 27px;
            min-height: 0;
        }

        .grid-x{
            > .cell{
                &:nth-child(1){
                    @include xy-cell(86%);
                }

                &:nth-child(2){
                    @include xy-cell(14%);
                }
            }
        }
    }
}
.grid-body-row, .grid-head{

    .grid-x{

        > .cell{

            &:nth-child(1){
                @include xy-cell(9.9%);
            }

            &:nth-child(2){
                @include xy-cell(12.9%);
            }

            &:nth-child(3){
                @include xy-cell(35.1%);
                word-wrap: break-word;
            }

            &:nth-child(4){
                @include xy-cell(42%);
            }
        }
    }
}
.grid-body-row{

    .filter-label-item {
        .label{
            margin: 0 0 6px;
        }
    }

    .grid-x{
        > .cell{
            &:nth-child(1){
                font-size: 13px;
                font-weight: 600;
                letter-spacing: 0.05px;
            }

            &:nth-child(2){
                font-family: $oswald-regular;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                letter-spacing: 0.7px;
            }

            &:nth-child(3){
                font-size: 13px;
                font-weight: 600;
                letter-spacing: 0.05px;
            }

            &:nth-child(4){
            }
        }
    }
}

.grid-head{
    padding: 0 0 0 25px;
    margin: 0 0 8px;
}

.grid-head-title{
    color: $light-gray4;
    font-family: $oswald-regular;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
}

.your-details{
    padding: 12px 0 0;

    > .grid-x{
        > .cell:nth-child(2){
            margin: 0 0 0 -50px;
        }
    }

    &.your-details-assortment{
        padding: 3px 0 0;

        .your-details-colrow{
            margin: 0 0 9px;
        }

        .custom-checkbox [type="checkbox"] + span{
            line-height: 19px;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.05px;

            &::before{
                top: 1px;
                left: -14px;
            }

            &::after{
                top: 3px;
                left: -12px;
            }
        }
    }
}

.dropzone-file{
    width: 227px;
}

.default-file{
    position: relative;
    padding: 5px 0 17px;

    input{
        position: absolute !important;
        visibility: visible !important;
        width: 100% !important;
        height: 100% !important;
        z-index: 1;
        opacity: 0;
    }

    .button{
        padding: 4px 5px 3px 5px;
        font-size: 12px;
        letter-spacing: 0.3px;
        margin: 0;
    }

    .default-file-name{
        position: relative;
        top: 4px;
        opacity: 0.8;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.04px;
        display: inline-block;
        padding: 0 0 3px 6px;
        max-width: 90px;
        overflow: hidden;
        line-height: 1;
        text-overflow: ellipsis;
    }
}

.your-details-colrow{
    margin: 0 0 24px;
}

.grid-body-row{
    background: $white;
    padding: 9px 0 3px 25px;
    margin: 0 0 7px;
}

.grid-margin-x > {
    .cell-campaing-name{
        @include xy-cell(20.5%);
    }

    .cell-campaing-date{
        @include xy-cell(13.2%);
    }
}

.your-details-colrow-key{
    @include xy-cell(44.3%, true,  28);
    opacity: 0.8;
    font-size: 13px;
    letter-spacing: 0.05px;
}

.your-details-colrow-val{
    @include xy-cell(55.3%, true,  28);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.05px;
}

.row-list-usp-outer{
    padding: 30px 0 12px;

    &.row-list-usp-outer-read{
        padding: 17px 0 39px;
    }
}

.add-news{
    position: absolute;
    right: 10px;
    top: 1px;
    font-size: 11px;

    span{
        display: inline-block;
        vertical-align: middle;
    }

    [class^="icon-"], [class*=" icon-"]{
        margin: 0 0 0 5px;
        vertical-align: middle;
        font-size: 20px;
    }
}

.news-more-button-box{
    padding: 17px 0 14px;
}

.your-details-foot-info{
    width: 100%;
    text-align: center;
}

@-moz-document url-prefix() {
    .cell-link{
        letter-spacing: -1px;
    }
}

.row-list-grid-news{

    .cell{

        p:last-child{
            margin: 0;
        }
    }
}
