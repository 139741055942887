body{
    overflow-y: scroll;
}

.content{
    padding: 23px 0 0;
}

.no-margin{
    margin: 0;
}

.js-off-canvas-overlay{
    position: absolute !important;
}

.m-double-row-select{

    .select2 + .select2{
        display: none;
    }
}