#{text-inputs()}, textarea{
    height: auto;
    min-height: 25px;
    padding: 2px 12px;
    font-family: $open-sans-semibold;

    &.bold-input, &.custom-input-bold{
        font-family: $open-sans-semibold;
    }
}

textarea{
    min-height: 124px;
    padding: 6px 12px;

    &.textarea-medium-height{
        min-height: 60px;
    }
}

.custom-checkbox, .custom-radio{
    position: relative;
    display: inline-block;
    padding-left: 21px;
    cursor: pointer;

    &.custom-radio-bold, &.custom-input-bold{
        font-family: $open-sans-semibold;
    }

    &.custom-chr-gutter{
        margin: 0 8px 0 0;
    }

    [type="checkbox"], [type="radio"]{
        display: none;
    }

    [type="checkbox"] + span, [type="radio"] + span{
        display: inline-block;
        vertical-align: top;

        &::before{
            position: absolute;
            top: 5px;
            left: 0;
            content: "";
            width: 15px;
            height: 15px;
            border-radius: 2px;
            background: $white;
            border: 1px solid $light-gray2;
            transition: $global-transition;
        }

    }

    [type="radio"] + span{

        &::before{
            width: 15px;
            height: 15px;
            border-radius: 50%;
            box-shadow: none;

        }
    }

    :checked + span:after{
        position: absolute;
        top: 7px;
        left: 2px;
        content: "\e906";
        @include icomoon;
        font-size: 12px;
    }

    :checked + span:before{
        box-shadow: none;
    }

    :disabled + span{
        opacity: 0.5;
        cursor: not-allowed;
    }

}
.custom-checkbox-plusminus{
    position: relative;
    display: inline-block;
    margin: 0;
    cursor: pointer;

    [type="checkbox"]{
        display: none;
    }

    [type="checkbox"] + span{
        display: block;
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid $primary-color;
        background: $primary-color;
        border-radius: 2px;
        transition: $global-transition;

        &::before,
        &::after{
            position: absolute;
            top: 50%;
            left: 3px;
            right: 3px;
            content: "";
            background: $white;
            transition: $global-transition;
            transform: rotate(0);
            height: 2px;
            margin: -1px 0 0;
        }
        &:after{
            transform: rotate(90deg);
            opacity: 1;
        }

    }

    :checked + span{
        background: $white;
        &:after,
        &:before{
            background: $primary-color;
        }

        &:after{
            opacity: 0;
        }
    }


    :disabled + span{
        opacity: 0.5;
        cursor: not-allowed;
    }

}

.custom-radio{

    :checked + span{

        &::before{
            border-color: $black;
        }

        &::after{
            top: 8px;
            font-size: 10px;
        }

    }
}

.label-desc{
    position: relative;
    top: -6px;

    small{
        display: block;
        font-size: 70%;
        line-height: 1px;
    }
}

.form-row{
    margin-bottom: 15px;

    &.form-row-half{
        margin-bottom: 8px;
    }

    &.form-row-middle{
        margin-bottom: 10px;
    }

    &.form-row-large{
        margin-bottom: 20px;
    }

    &.form-row-xlarge{
        margin-bottom: 24px;
    }

    input, textarea{
        margin: 0;
    }
}

.form-row-up{
    padding-top: 50px;
}

.form-cell-has-tip{
    position: relative;

    .info-tip{
        position: absolute;
        right: -21px;
        top: 5px;
    }
}

.upper-label{
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px 0 0;
    font-family: $oswald-regular;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1px;
}

.slim-label{
    font-family: $open-sans-light;
}

.filter-select-box{
    padding: 0 10px;
    margin-bottom: 11px;

    .select2-container{
        margin: 0;
    }
}

.custom-select-attr{

    & + .select2-container{
        width: 227px !important;
    }
}

.custom-select-attr-long{

    & + .select2-container{
        width: 285px !important;
    }
}

.filter-select-box-btn-box{
    padding: 0 0 0 73px;
}

.short-title, .short-input{
    max-width: 233px;
}

.short-textarea{
    max-width: 494px;
}

.search-box{
    position: relative;
    padding-right: 30px;
    margin: 0 0 0 8px;
    width: 257px;
    display: inline-block;

    .button{
        position: absolute;
        right: 0;
        top: 2px;
    }
}

.form-row-middle-select{
    .select2{
        width: 233px !important;
    }

}
.form-row-middle-result{
    .label{
        margin: 0 5px 10px 0;
    }
}
.middle-select{
    width: 228px;
    margin: 0 9px 0 0;
    display: inline-block;
    vertical-align: middle;
}
