.pagination-box {
    padding: 10px 0 0;
}

.pagination-box-padded{
    padding: 20px 0 30px;
}

.pagination-list{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    color: $primary-color;
    font-family: $open-sans-semibold;

    li{
        display: inline-block;
        margin: 0 10px;
        text-align: center;

        &.pagination-gap{
            margin: 0;
        }

        &.active{

            span{
                display: inline-block;
                color: $white;
                background: $primary-color;
                padding: 1px 5px;
                border-radius: 50%;
            }
        }

        &.pagination-previous, &.pagination-next{
            margin: 0;

            a::before{
                @include icomoon;
                position: relative;
                top: -2px;
                display: inline-block;
                transform: rotate(90deg);
                font-size: 6px;
                content: "\e910";
            }
        }

        &.pagination-previous{

            a::before{
                left: 5px;
            }
        }

        &.pagination-next{

            a::before{
                transform: rotate(-90deg);
            }
            a::after{
                display: none;
            }
        }
    }
}
