.info-tip{
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    line-height: 15px;
    color: $primary-color;
    outline: none;

    &::before{
        @include icomoon;
        content: "\e90d";
    }
}
