.tabs{
    @include xy-grid;
    align-items: flex-end;
    font-family: $oswald-regular;
    text-transform: uppercase;
    border: none;

    &.tabs-equal-border{

        .tabs-title{

            a{
                display: block;
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
}

.tabs-title{
      @include xy-cell(auto, false);
      text-align: center;
      outline: none;

      &.is-active{

          a{
              border-color: $primary-color;
          }
      }

      &:hover{

          a{
              border-color: $primary-color;
              color: $primary-color;
          }
      }

      a{
          outline: none;
          display: inline-block;
          border-bottom: 2px solid transparent;
          transition: color $global-transition, border-color $global-transition
      }
}

.accordion-simple-content{
    font-family: $open-sans-light;

    .accordion-title{
        padding: 6px 36px 0 9px;
    }
}
