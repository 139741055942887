.grid-product-single{

    > .cell{

        &:nth-child(1){
            @include xy-cell(75.2%);
        }

        &:nth-child(2){
            @include xy-cell(24.8%);
        }
    }
}

.prod-mini-accordion-box{
    padding: 17px 0 31px;
}

.mark-id-result{
    padding: 0 0 0 13px;

    .label{
        margin: 0 2px 3px 0;
    }
}
