.article-short-info{
    padding: 39px 87px 0 10px;

    &.article-short-info-readable{
        padding: 9px 87px 0 10px;
    }
}

.home-av{
    padding: 11px 0 13px 0;

    &.home-av-read{
        padding: 0 0 22px;
    }
}

.home-av-title{
    display: block;
    margin: 0 0 16px 200px;
    max-width: 240px;
    line-height: 1.4;
}

.home-av-list-row{
    margin-bottom: 10px;

    .row-list-item{
        margin: 0;
        padding: 8px 0 8px 5px;
        text-transform: uppercase;
        font-family: $oswald-regular;
        font-size: 14px;
    }

    &.home-av-list-row-read{

        .row-list-item{
            padding: 10px 10px 10px 18px;
        }
    }

    .row-move-nav{
        opacity: 0;
        visibility: hidden;
    }

    &.home-av-list-row-checked{

        .row-move-nav{
            opacity: 1;
            visibility: visible;
        }
    }
}

.home-av-site{
    padding-top: 12px;
    display: block;
}

.home-av-site-box{
    margin-bottom: 24px;

    &:last-child{
        margin: 0;
    }

    &.home-av-site-box-readable{
        margin: 0 0 10px;
    }
}
