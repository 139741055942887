.cke{
    border-radius: 2px;
}

.cke_top{
    background: $light-gray9 !important;
    background: -webkit-linear-gradient($light-gray9 0%, $light-gray11 100%) !important;
    background: -o-linear-gradient($light-gray9 0%, $light-gray11 100%) !important;
    background: linear-gradient($light-gray9 0%, $light-gray11 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$light-gray9', endColorstr='$light-gray11',GradientType=0 ) !important;
}

.cke_chrome{
    border-color: $light-gray2 !important;
}
