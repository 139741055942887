.login-box{
    max-width: 407px;
    margin: auto;
    padding-top: 120px;

    .button{
        padding: 0.55em 0.9em;
    }
}

.login-box-title{
    margin: 0 0 27px;
    text-align: center;
}

.login-box-label{
    font-family: $oswald-regular;
    text-transform: uppercase;
    color: $light-gray4;
    font-size: 14px;
}

.login-box-field{
    height: 50px;
    margin: 0;

    &:focus{
        border-color: $light-gray5;
    }
}

.login-box-row{
    margin-bottom: 20px;
}

.login-box-field-wrap{
    padding-left: 15px;
}
