$col1: 31.4%;
$col2: 17.2%;
$col3: 18%;
$col4: 17.1%;
$col5: 16.3%;

.sharing-box-head{
    padding: 0 8px;
    margin-bottom: 6px;
    font-family: $oswald-regular;
    color: $medium-gray;
    text-transform: uppercase;

    > .cell{

        &:nth-child(1){
            @include xy-cell($col1);
        }

        &:nth-child(2){
            @include xy-cell($col2);
        }

        &:nth-child(3){
            @include xy-cell($col3);
        }

        &:nth-child(4){
            @include xy-cell($col4);
        }

        &:nth-child(5){
            @include xy-cell($col5);
        }

    }
}

.sharing-box-body-outer{
    padding: 7px 0 13px;
    margin: 0 0 14px;
    border: 1px solid $light-gray7;
    background: $white;
}

.sharing-box-body{
    padding: 6px 0;

    > .cell{
        position: relative;
        padding-right: 25px;

        &:nth-child(1){
            @include xy-cell($col1);
        }

        &:nth-child(2){
            @include xy-cell($col2);
        }

        &:nth-child(3){
            @include xy-cell($col3);
        }

        &:nth-child(4){
            @include xy-cell($col4);
        }

        &:nth-child(5){
            @include xy-cell($col5);
        }

    }

    &.sharing-box-body-bg{
        min-height: 42px;
        padding: 8px 0;
        margin: 0 0 6px;
        border: 1px solid $light-gray7;
        background: $white;
    }
}

.sharing-box-body-before{
    position: absolute;
    left: -30px;
}

.sharing-box-outer{
    margin-bottom: 15px;
}

.sharing-box-body-datebox{
    padding: 6px 0;
    display: none;
}

.js-sharing-box-body-closest.active{
    .sharing-box-body-datebox{
        display: block;
    }
}
