.js-off-canvas-overlay{
    top: 72px;
}

.position-left{
    padding: 84px 15px 10px 27px;
}

.nav{
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-family: $oswald-regular;
    font-size: 19px;

    > li{
        margin: 0 0 18px;
        list-style: none;

        &.active{

            > a{
                display: block;
                color: $primary-color;

                &::after{
                    position: absolute;
                    right: 0;
                    top: 5px;
                    @include icomoon;
                    content: "\e902";
                    color: $primary-color;
                    display: inline-block;
                    font-size: 16px;
                }

                &::before{
                    color: $primary-color;
                }
            }
        }
    }

    ul{
        padding: 17px 0 0 44px;
        font-size: 17px;

        li{
            margin: 0 0 7px;
            list-style: none;

            &.active{

                a{
                    color: $primary-color;
                }
            }
        }
    }

    a{
        display: inline-block;
        outline: none;
        color: $medium-gray;

        &:hover{
            color: $primary-color;

            &::before, &::after{
                color: $primary-color;
            }
        }
    }
}

.nav-item-icon{

    > a{
        position: relative;
        padding: 0 20px 0 41px;

        &::before{
            position: absolute;
            left: 0;
            top: 4px;
            @include icomoon;
            color: $medium-gray;
            display: inline-block;
            margin: 0 21px 0 0;
        }

    }

    &.nav-item-dashboard > a::before{
        content: "\e90b";
    }

    &.nav-item-admin > a::before{
        content: "\e90a";
    }

    &.nav-item-media > a::before{
        content: "\e90c";
    }

    &.nav-item-article > a::before{
        content: "\e914";
    }

    &.nav-item-handler > a::before{
        content: "\e903";
    }

    &.nav-item-logout > a::before{
        content: "\e90f";
    }
}
