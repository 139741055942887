.button, button{
    text-transform: uppercase;

    &.small{
        padding: 0.35em 0.6em;
    }

    &.button-icon{
        padding: 1px 4px 3px;
        margin: 0;
        border-radius: 2px;

        [class^="icon-"], [class*=" icon-"]{
            margin: 0;
            font-size: 12px;
        }
    }

    [class^="icon-"], [class*=" icon-"]{
        display: inline-block;
        margin: 0 3px 0 0;
        font-size: 15px;
    }

    &.hollow{

        &:hover{
            color: $white;
            background: $primary-color;
            border-color: $primary-color;

            [class^="icon-"], [class*=" icon-"]{
                color: $white;
            }
        }
    }
}
