.delete-item{
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 5px;
    font-size: 19px;
    line-height: 20px;
    padding: 1px;
    cursor: pointer;
    box-shadow: 0 0 0 1px $primary-color inset;
    color: $primary-color;
    transition: $global-transition;

    &:hover{
        box-shadow: 0 0 0 1px smart-scale($primary-color, -7%) inset;
        color: smart-scale($primary-color, -7%);
    }
}

.edit{
    display: inline-block;
    vertical-align: middle;
    padding: 1px;
    color: $primary-color;
    font-size: 18px;
    line-height: 18px;
    box-shadow: 0 0 0 1px $primary-color inset;
    transition: $global-transition;

    &:hover{
        color: smart-scale($primary-color, -5%);
        box-shadow: 0 0 0 1px smart-scale($primary-color, -5%) inset;
    }
}
