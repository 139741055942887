.release_status{
    @include xy-gutters(-10);
    text-transform: uppercase;
    font-family: $oswald-regular;

    &.release_status-in-title{
        position: relative;
        top: 2px;
    }
}

.release_status-item{
    padding: 0 5px;
    outline: none;
}

.release_status-icon{
    color: $color-green;
    font-size: 9px;
    margin: 0 0 2px;

    span{
        height: 13px;
        display: block;
        vertical-align: middle;
        display: flex;
        align-items: center;
    }
}

.release_status-text{
    font-size: 8px;
    text-align: center;
}
