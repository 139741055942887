// Fonts
$oswald-regular: 'Oswald-Regular', Helvetica, Roboto, Arial, sans-serif;
$oswald-light: 'Oswald-Light', Helvetica, Roboto, Arial, sans-serif;
$open-sans-regular: 'OpenSans-Regular', Helvetica, Roboto, Arial, sans-serif;
$open-sans-light: 'OpenSans-Light', Helvetica, Roboto, Arial, sans-serif;
$open-sans-semibold: 'OpenSans-SemiBold', Helvetica, Roboto, Arial, sans-serif;

// Colors
$light-gray2: #c5c5c5;
$light-gray3: #484848;
$light-gray4: #949494;
$light-gray5: #a6a6a6;
$light-gray6: #e3e3e3;
$light-gray7: #e0e0e0;
$light-gray8: #ebebeb;
$light-gray9: #f1f1f1;
$light-gray10: #d6d6d6;
$light-gray11: #e7e9e7;
$light-gray12: #d8d8d8;


$color-green: #45aa5d;
// Animation
$global-transition: 0.3s;
