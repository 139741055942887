.cell{

    &.vertical-center{
        align-self: center;
    }
}

.grid-x{

    &.grid-vertical-center{
        align-items: center;
    }
}
