h1, h2, h3, h4, h5, h6{
    text-transform: uppercase;
    letter-spacing: 1px;
    word-spacing: 3px;

    &.title-has-icon{

        span{
            display: inline-block;
            vertical-align: middle;
        }
    }
}

h1{
    margin-bottom: 20px;
}

h3{
    margin-bottom: 10px;
}

strong{
    font-family: $open-sans-semibold;
}

.thin-font{
    font-family: $open-sans-light;
}
