.edit-info-form{
    padding: 0 20px 13px 84px;
}

.edit-info-form-field{
    max-width: 221px;
}

.edit-info-form-textarea{
    max-width: 490px;
}
