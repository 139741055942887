.details-subfield-box{
    margin-bottom: 20px;
    padding: 4px 0 0 77px;

    &.details-subfield-box-mod{
        padding-left: 50px;
    }

    &.details-subfield-box-short{
        margin: 0 0 9px -64px;
    }

    &.details-subfield-box-text{
        padding-left: 0;
        margin: 0 0 17px;
    }

    &.details-subfield-box-inwhite{
        padding-left: 0;
    }
}

.details-selects-holder{
    padding: 20px 0 13px;

    .details-subfield-box{
        margin-bottom: 11px;
    }
}

.video-edit-box{
    padding-right: 25.2%;
}

.details-subfield-box-outer{
    margin: 0 0 0 -40px;
}

.details-subfield-box-inwhite-outer{
    margin: 0 0 0 -67px;
}
