.edit-user-form{
    padding: 3px 20px 0;
}

.edit-user-form-title{
    letter-spacing: normal;
    margin: 0 0 13px;

    &.edit-user-form-title-right{
        margin: 0 0 17px;
    }
}

.edit-user-form-left-col{
    padding: 0 86px 0 60px;
}

.edit-user-form-right-col{
    padding: 0 122px 0 22px;
}

.finish-box{
    margin-bottom: 30px;
    text-align: center;

    .button{
        margin: 0 5px;
    }

    &.finish-box-top-gutter{
        padding-top: 9px;
    }

    &.finish-box-two{

        .button{
            min-width: 110px;
        }
    }
}
