.header{
    position: relative;
    z-index: 15;
    padding: 15px 0;
    border-top: 2px solid $primary-color;
    color: $primary-color;
    background: $black;

    @include breakpoint(large) {
        padding: 11px 15px 9px;
    }
}

.logo-col{
    text-align: center;
}

.logo{
    display: inline-block;
}

.header-actions{
    text-align: right;
    font-size: 25px;
}

.notifications{
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.notifications-count{
    position: absolute;
    right: -14px;
    top: -4px;
    color: $black;
    background: $primary-color;
    border-radius: 50%;
    font-size: 9px;
    min-width: 17px;
    min-height: 17px;
    text-align: center;
    padding: 3px 2px 2px;
}

.logout{
    display: inline-block;
    vertical-align: middle;
}

.header-actions-line{
    display: inline-block;
    vertical-align: middle;
    height: 27px;
    width: 1px;
    margin: 0 15px;
    background: $light-gray3;

    @include breakpoint(medium) {
        height: 50px;
    }
}
