$path-icomoon: "../fonts/icomoon/fonts/";
$path: "../fonts/";

@font-face {
    font-family: 'Oswald-Regular';
    src: url('#{$path}Oswald-Regular.eot?#iefix') format('embedded-opentype'),
         url('#{$path}Oswald-Regular.woff') format('woff'),
         url('#{$path}Oswald-Regular.ttf')  format('truetype'),
         url('#{$path}Oswald-Regular.svg#Oswald-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Oswald-Light';
  src: url('#{$path}Oswald-Light.eot?#iefix') format('embedded-opentype'),
       url('#{$path}Oswald-Light.woff') format('woff'),
       url('#{$path}Oswald-Light.ttf')  format('truetype'),
       url('#{$path}Oswald-Light.svg#Oswald-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url('#{$path}OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$path}OpenSans-Light.woff') format('woff'),
        url('#{$path}OpenSans-Light.ttf')  format('truetype'),
        url('#{$path}OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('#{$path}OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('#{$path}OpenSans-SemiBold.woff') format('woff'),
         url('#{$path}OpenSans-SemiBold.ttf')  format('truetype'),
         url('#{$path}OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('#{$path}OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('#{$path}OpenSans-Regular.woff') format('woff'),
       url('#{$path}OpenSans-Regular.ttf')  format('truetype'),
       url('#{$path}OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('#{$path-icomoon}icomoon.eot?wu36bw');
  src:  url('#{$path-icomoon}icomoon.eot?wu36bw#iefix') format('embedded-opentype'),
    url('#{$path-icomoon}icomoon.ttf?wu36bw') format('truetype'),
    url('#{$path-icomoon}icomoon.woff?wu36bw') format('woff'),
    url('#{$path-icomoon}icomoon.svg?wu36bw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-circle:before{
    content: "";
    width: 13px;
    height: 13px;
    display: block;
    border: 2px solid #d8d8d8;
    border-radius: 50%;
}





[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus-circle:before {
  content: "\e916";
}
.icon-arrow .path1:before {
  content: "\e900";
}
.icon-arrow .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-arrow-dir:before {
  content: "\e902";
}
.icon-building:before {
  content: "\e903";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-check:before {
  content: "\e905";
}
.icon-check-bold:before {
  content: "\e906";
}
.icon-cross:before {
  content: "\e907";
}
.icon-curve-arrow:before {
  content: "\e908";
  color: #000;
}
.icon-edit:before {
  content: "\e909";
}
.icon-gear:before {
  content: "\e90a";
}
.icon-home:before {
  content: "\e90b";
}
.icon-image:before {
  content: "\e90c";
}
.icon-info-button:before {
  content: "\e90d";
}
.icon-info-button2:before {
  content: "\e90e";
}
.icon-logout:before {
  content: "\e90f";
}
.icon-mini-arrow:before {
  content: "\e910";
}
.icon-reset-hover:before {
  content: "\e911";
}
.icon-ring-bell:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
.icon-shirt:before {
  content: "\e914";
}
.icon-trash:before {
  content: "\e915";
}
