.label{
    text-transform: uppercase;
    font-family: $oswald-light;
    font-size: 12px;
    position: relative;
    transition: $global-transition;

    i{
        @include vertical-center;
        right: 3px;
        background: $white;
        color: $primary-color;
        font-size: 5px;
        cursor: pointer;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        text-align: center;
        padding: 4px 0 0;
        font-weight: 800;
        opacity: 1;
        visibility: visible;
        transition: $global-transition;
    }

    &.label-checkbox{
        cursor: pointer;
        padding: 2px 24px 2px 7px;
        border: 1px solid $primary-color;

        &:not(.active){
            background: $white;
            color: $primary-color;

            i{
                opacity: 0;
                visibility: hidden;
            }
        }

        &:hover{
            background: $primary-color;
            color: $white;
        }
    }
}
