/*!
 * Datetimepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
 .datepicker{
     margin: 0;
    //  padding-right: 27px;
    padding-right: 22px;
    padding-left: 3px;
}

.datetimepicker {
	// padding: 4px;
	// margin-top: 1px;
	// -webkit-border-radius: 4px;
	// -moz-border-radius: 4px;
	// border-radius: 4px;
	direction: ltr;
}

.datetimepicker-inline {
	width: 220px;
}

.datetimepicker.datetimepicker-rtl {
	direction: rtl;
}

.datetimepicker.datetimepicker-rtl table tr td span {
	float: right;
}

.datetimepicker-dropdown, .datetimepicker-dropdown-left {
	top: 0;
	left: 0;
}

[class*=" datetimepicker-dropdown"]:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #cccccc;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position: absolute;
}

[class*=" datetimepicker-dropdown"]:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #ffffff;
	position: absolute;
}

[class*=" datetimepicker-dropdown-top"]:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 7px solid #cccccc;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-bottom: 0;
}

[class*=" datetimepicker-dropdown-top"]:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #ffffff;
	border-bottom: 0;
}

.datetimepicker-dropdown-bottom-left:before {
	top: -7px;
	right: 6px;
}

.datetimepicker-dropdown-bottom-left:after {
	top: -6px;
	right: 7px;
}

.datetimepicker-dropdown-bottom-right:before {
	top: -7px;
	left: 6px;
}

.datetimepicker-dropdown-bottom-right:after {
	top: -6px;
	left: 7px;
}

.datetimepicker-dropdown-top-left:before {
	bottom: -7px;
	right: 6px;
}

.datetimepicker-dropdown-top-left:after {
	bottom: -6px;
	right: 7px;
}

.datetimepicker-dropdown-top-right:before {
	bottom: -7px;
	left: 6px;
}

.datetimepicker-dropdown-top-right:after {
	bottom: -6px;
	left: 7px;
}

.datetimepicker > div {
	display: none;
}

.datetimepicker.minutes div.datetimepicker-minutes {
	display: block;
}

.datetimepicker.hours div.datetimepicker-hours {
	display: block;
}

.datetimepicker.days div.datetimepicker-days {
	display: block;
}

.datetimepicker.months div.datetimepicker-months {
	display: block;
}

.datetimepicker.years div.datetimepicker-years {
	display: block;
}

.datetimepicker table {
	margin: 0;
}

.datetimepicker  td,
.datetimepicker th {
	text-align: center;
	width: 20px;
	height: 20px;
	border: none;
}

.table-striped .datetimepicker table tr td,
.table-striped .datetimepicker table tr th {
	background-color: transparent;
}

.datetimepicker table tr td.minute:hover {
	background: #eeeeee;
	cursor: pointer;
}

.datetimepicker table tr td.hour:hover {
	background: #eeeeee;
	cursor: pointer;
}

.datetimepicker table tr td.day:hover {
	background: $primary-color;
    color: $black;
	cursor: pointer;
}

.datetimepicker table tr td.old,
.datetimepicker table tr td.new {
	color: #999999;
}

.datetimepicker table tr td.disabled,
.datetimepicker table tr td.disabled:hover {
	background: none;
	color: #999999;
	cursor: default;
}

.datetimepicker table tr td.today,
.datetimepicker table tr td.today:hover,
.datetimepicker table tr td.today.disabled,
.datetimepicker table tr td.today.disabled:hover {
	background-color: #fde19a;
	background-image: -moz-linear-gradient(top, #fdd49a, #fdf59a);
	background-image: -ms-linear-gradient(top, #fdd49a, #fdf59a);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
	background-image: -webkit-linear-gradient(top, #fdd49a, #fdf59a);
	background-image: -o-linear-gradient(top, #fdd49a, #fdf59a);
	background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
	border-color: #fdf59a #fdf59a #fbed50;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.datetimepicker table tr td.today:hover,
.datetimepicker table tr td.today:hover:hover,
.datetimepicker table tr td.today.disabled:hover,
.datetimepicker table tr td.today.disabled:hover:hover,
.datetimepicker table tr td.today:active,
.datetimepicker table tr td.today:hover:active,
.datetimepicker table tr td.today.disabled:active,
.datetimepicker table tr td.today.disabled:hover:active,
.datetimepicker table tr td.today.active,
.datetimepicker table tr td.today:hover.active,
.datetimepicker table tr td.today.disabled.active,
.datetimepicker table tr td.today.disabled:hover.active,
.datetimepicker table tr td.today.disabled,
.datetimepicker table tr td.today:hover.disabled,
.datetimepicker table tr td.today.disabled.disabled,
.datetimepicker table tr td.today.disabled:hover.disabled,
.datetimepicker table tr td.today[disabled],
.datetimepicker table tr td.today:hover[disabled],
.datetimepicker table tr td.today.disabled[disabled],
.datetimepicker table tr td.today.disabled:hover[disabled] {
	background-color: #fdf59a;
}

.datetimepicker table tr td.today:active,
.datetimepicker table tr td.today:hover:active,
.datetimepicker table tr td.today.disabled:active,
.datetimepicker table tr td.today.disabled:hover:active,
.datetimepicker table tr td.today.active,
.datetimepicker table tr td.today:hover.active,
.datetimepicker table tr td.today.disabled.active,
.datetimepicker table tr td.today.disabled:hover.active {
	background-color: $primary-color;
}

.datetimepicker table tr td.active,
.datetimepicker table tr td.active:hover,
.datetimepicker table tr td.active.disabled,
.datetimepicker table tr td.active.disabled:hover {
	background-color: $primary-color;
	color: $black;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datetimepicker table tr td.active:hover,
.datetimepicker table tr td.active:hover:hover,
.datetimepicker table tr td.active.disabled:hover,
.datetimepicker table tr td.active.disabled:hover:hover,
.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:hover.active,
.datetimepicker table tr td.active.disabled,
.datetimepicker table tr td.active:hover.disabled,
.datetimepicker table tr td.active.disabled.disabled,
.datetimepicker table tr td.active.disabled:hover.disabled,
.datetimepicker table tr td.active[disabled],
.datetimepicker table tr td.active:hover[disabled],
.datetimepicker table tr td.active.disabled[disabled],
.datetimepicker table tr td.active.disabled:hover[disabled] {
	background-color: $primary-color;
}

.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:hover.active {
	background-color: $primary-color;
}

.datetimepicker table tr td span {
	display: block;
	width: 23%;
	height: 54px;
	line-height: 54px;
	float: left;
	margin: 1%;
	cursor: pointer;
}

.datetimepicker .datetimepicker-hours span {
	height: 26px;
	line-height: 26px;
}

.datetimepicker .datetimepicker-hours table tr td span.hour_am,
.datetimepicker .datetimepicker-hours table tr td span.hour_pm {
	width: 14.6%;
}

.datetimepicker .datetimepicker-hours fieldset legend,
.datetimepicker .datetimepicker-minutes fieldset legend {
	margin-bottom: inherit;
	line-height: 30px;
}

.datetimepicker .datetimepicker-minutes span {
	height: 26px;
	line-height: 26px;
}

.datetimepicker table tr td span:hover {
	background: #eeeeee;
}

.datetimepicker table tr td span.disabled,
.datetimepicker table tr td span.disabled:hover {
	background: none;
	color: #999999;
	cursor: default;
}

.datetimepicker table tr td span.active,
.datetimepicker table tr td span.active:hover,
.datetimepicker table tr td span.active.disabled,
.datetimepicker table tr td span.active.disabled:hover {
	background-color: $primary-color;
	color: $black;
}

.datetimepicker table tr td span.active:hover,
.datetimepicker table tr td span.active:hover:hover,
.datetimepicker table tr td span.active.disabled:hover,
.datetimepicker table tr td span.active.disabled:hover:hover,
.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:hover.active,
.datetimepicker table tr td span.active.disabled,
.datetimepicker table tr td span.active:hover.disabled,
.datetimepicker table tr td span.active.disabled.disabled,
.datetimepicker table tr td span.active.disabled:hover.disabled,
.datetimepicker table tr td span.active[disabled],
.datetimepicker table tr td span.active:hover[disabled],
.datetimepicker table tr td span.active.disabled[disabled],
.datetimepicker table tr td span.active.disabled:hover[disabled] {
	background-color: $primary-color;
}

.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:hover.active {
    background-color: $primary-color;
	color: $black;
}

.datetimepicker table tr td span.old {
	color: #999999;
}

.datetimepicker th.switch {
	width: 145px;
}

.datetimepicker th span.glyphicon {
	pointer-events: none;
}

.datetimepicker thead tr:first-child th,
.datetimepicker tfoot th {
	cursor: pointer;
}

.datetimepicker thead tr:first-child th:hover,
.datetimepicker tfoot th:hover {
	background: #eeeeee;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i,
.input-group.date .input-group-addon span {
	cursor: pointer;
	width: 14px;
	height: 14px;
}


.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle {
  *margin-bottom: -3px;
}

.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}

.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}

.dropdown-menu li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
  color: #ffffff;
  text-decoration: none;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #BF6C3C, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#BF6C3C), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #BF6C3C, #0077b3);
  background-image: -o-linear-gradient(top, #BF6C3C, #0077b3);
  background-image: linear-gradient(to bottom, #BF6C3C, #0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffBF6C3C', endColorstr='#ff0077b3', GradientType=0);
}

.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #BF6C3C, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#BF6C3C), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #BF6C3C, #0077b3);
  background-image: -o-linear-gradient(top, #BF6C3C, #0077b3);
  background-image: linear-gradient(to bottom, #BF6C3C, #0077b3);
  background-repeat: repeat-x;
  outline: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffBF6C3C', endColorstr='#ff0077b3', GradientType=0);
}

.dropdown-menu .disabled > a,
.dropdown-menu .disabled > a:hover {
  color: #999999;
}

.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  cursor: default;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.open {
  *z-index: 1000;
}

.open > .dropdown-menu {
  display: block;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: "";
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
     -moz-border-radius: 0 6px 6px 6px;
          border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  -webkit-border-radius: 5px 5px 5px 0;
     -moz-border-radius: 5px 5px 5px 0;
          border-radius: 5px 5px 5px 0;
}

.dropdown-submenu > a:after {
  display: block;
  float: right;
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-right: -10px;
  border-color: transparent;
  border-left-color: #cccccc;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: " ";
}

.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
     -moz-border-radius: 6px 0 6px 6px;
          border-radius: 6px 0 6px 6px;
}

.dropdown .dropdown-menu .nav-header {
  padding-right: 20px;
  padding-left: 20px;
}

.datetimepicker{

    .switch{
        color: $body-font-color;
    }

    .prev, .next{
        position: relative;

        i{
            @include absolute-center;
            color: $primary-color;

            &::before{
                display: block;
                @include icomoon;
                font-size: 17px;
                content: "\e902";
            }
        }
    }

    .prev{

        i::before{
            transform: rotate(180deg);
        }
    }
}

.datepicker-box{
    position: relative;

    &::after{
        @include icomoon;
        position: absolute;
        right: 6px;
        top: 5px;
        font-size: 14px;
        color: $primary-color;
        content: "\e904";
    }
}
