.accordion{

    &.accordion-collapse-content{

        > .accordion-item{

            > .accordion-content{
                padding: 0;
            }
        }
    }

    &.accordion-dark-theme{

        .accordion-item{
            border-radius: 2px;
            background: $light-gray;
            box-shadow: 0 1px 0 0 $light-gray6;
        }

        .accordion-content{
            background: transparent;
        }
    }

    &.accordion-global-holder{

        > .accordion-item{
            margin-bottom: 5px;

            &:not(.is-active){
                background: $light-gray;
            }
        }
    }

    &.accordion-bordered{

        > .accordion-item{
            margin: 0 0 12px;
            border-radius: 2px;
            box-shadow: 0 0 0 1px $light-gray inset;

            &.is-active{
                box-shadow: 0 0 0 1px $primary-color inset;
            }
        }

        .accordion-title{
            font-size: 12px;
        }

        .accordion-content{
            padding: 0 10px 15px 58px;
            background: transparent;
        }

        .accordion-trigger{
            font-size: 14px;
            line-height: 21px;
            height: 14px;
            top: 13px;
        }

        &.accordion-bordered-mod{

            .accordion-content{
                padding-left: 22px;
            }
        }

        &.accordion-bordered-thin{

            .accordion-content{
                padding: 10px;
            }
        }

        &.accordion-bordered-mod-col{

            .accordion-content{
                padding-left: 30px;
            }
        }
    }

    &.accordion-mini{

        .accordion-title{
            font-size: 12px;
        }

        .accordion-trigger{
            font-size: 14px;
            line-height: 21px;
            height: 14px;
            top: 13px;
        }
    }
}

.accordion-trigger{
    z-index: 2;
    position: absolute;
    right: 11px;
    top: 10px;
    color: $primary-color;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    letter-spacing: normal;
    transform: rotate(180deg);
    transition: color $global-transition;

    &:hover{
        color: smart-scale($primary-color, -7%);
    }

    &[aria-expanded="true"], .accordion-item.is-active > &{
        transform: rotate(0) !important;
        line-height: 20px;
    }

    [class^="icon-"], [class*=" icon-"]{
        display: block;
    }
}

.accordion-title{
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 40px;
    padding-right: 37px;
    font-family: $oswald-regular;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color $global-transition;

    > .grid-x{
        min-height: 26px;
    }
}

.accordion-item{
    position: relative;
    margin: 0 0 7px;
    background: $white;

    &:first-child{

        .accordion-title{

            .row-move-nav{

                .row-move-up{
                    color: $light-gray12;
                }
            }
        }
    }

    &:last-child{

        .accordion-title{

            .row-move-nav{

                .row-move-down{
                    color: $light-gray12;
                }
            }
        }
    }
}

.accordion-title-logo{
    display: inline-block;
    max-width: 40px;

    img{
        max-height: 26px;
    }
}

.accordion-title-time{
    display: inline-block;
    font-size: 13px;
    letter-spacing: normal;
    font-family: $open-sans-semibold;
}

.accordion-content-pic{
    padding: 8px 20px;
    border: 2px solid $light-gray;
    text-align: center;
}

.accordion-content-btn{
    max-width: 433px;
    margin: 0 0 0 auto;
    padding: 12px 0 0 0;

    .button{
        margin: 0;
    }
}

.accordion-left-cell{
    @include xy-cell(18.1%);

    .accordion-management &{
        @include xy-cell(25.7%);
        padding: 0 0 0 6px;
    }

    .accordion-simple-content &{
        padding: 0 0 0 17px;
    }

    .accordion-item-full > .accordion-title > .grid-vertical-center &{
        @include xy-cell(90.7%);
    }
}

.accordion-middle-cell{
    @include xy-cell(65%);
}

.accordion-right-cell{
    @include xy-cell(81.9%);
}

.accordion-buttons-cell{
    @include xy-cell(16.9%);
    text-align: right;

    &.accordion-buttons-cell-top{
        padding-top: 4px;
    }

    .accordion-management &{
        @include xy-cell(9.3%);
    }
}

.accordion{

    &.accordion-horizontal-trigger{

        .accordion-trigger{
            transform: rotate(90deg);

            &[aria-expanded="true"], .accordion-item.is-active > &{
                transform: rotate(180deg) !important;
            }
        }
    }
}

.accordion-management{

    .accordion-title{
        padding: 5px 37px 5px 10px;
    }

    .accordion-content{
        padding: 0;
    }

    .accordion-item{
        margin: 0 0 5px;

        .accordion-buttons-cell{
            transition: $global-transition;
            visibility: hidden;
            opacity: 0;
        }

        &.is-active{
            .accordion-buttons-cell{
                visibility: visible;
                opacity: 1;
            }
        }

        .accordion-simple-content{
            .accordion-buttons-cell{
                visibility: hidden;
                opacity: 0;
            }
        }
    }
}

.accordion-grid{

    > .cell{
        @include xy-cell(auto);
    }

    &.accordion-grid-attr{

        > .cell{
            flex: auto;

            &:nth-child(1){
                @include xy-cell(43.5%);
            }

            &:nth-child(2){
                @include xy-cell(40%);
            }
            &:nth-child(3){
                @include xy-cell(10%);
            }
        }
    }
}

.accordion-sub-category{
    border-top: 2px solid $secondary-color;
    border-radius: 2px;
}


.accordion-management{
    .accordion-item{
        .accordion-simple-content{
            .accordion-sub-inner{
                border: 1px solid $white;
                border-radius: 2px;
                transition: $global-transition;

                &:hover{
                    border-color: $primary-color;

                    .accordion-buttons-cell{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.accordion-cell-custom-ff{
    font-family: $open-sans-semibold;
    text-transform: none;
    letter-spacing: 0;
    font-size: 12px;
}

.accordion-flag{
    display: inline-block;
    vertical-align: middle;
    margin: -3px 5px 0 0;
}

.accordion-mini-grid{

    > .cell{

        &:nth-child(1){
            @include xy-cell(21.7%);
        }

        &:nth-child(2){
            @include xy-cell(78.3%);
        }
    }
}

.mark-id-input{
    min-width: 232px;
}
