$h-padding: 10px;

.box{
    padding: 20px $h-padding;
    margin-bottom: 40px;
    background: $light-gray;
    border-radius: 2px;
    border-bottom: 1px solid $light-gray6;

    &.box-article{
        padding-bottom: 0;
    }

    &.box-edit-user-form{
        padding-bottom: 0;
        margin-bottom: 14px;
    }

    &.box-edit-info-form{
        padding-bottom: 0;
        margin-bottom: 20px;
    }

    &.box-fake-height{
        min-height: 857px;
    }

    &.box-padded-sides{
        padding-right: 15px;
        padding-left: 15px;
    }

    &.box-white{
        margin: 0 0 15px;
        padding: 10px 27px;
        line-height: 1.4;
        border: none;
        background: $white;
    }

    &.box-medias{
        margin: 0 0 2px;
        padding: 17px 27px 17px 44px;
    }

    &.box-white-padr{
        padding-right: 10px;
    }

    &.box-no-margin{
        margin-bottom: 0;
    }

    &.box-no-padding{
        padding: 0;
    }

    &.box-vert-gutter{
        padding: 27px 10px 9px;
    }

    &.box-athletes{
        padding: 11px 19px 15px 16px;
        margin: 0;
    }
}

.boxed-title{
    padding: 0 $h-padding;

    &.boxed-title-medias{
        padding-left: 44px;
    }
}

.box-end-button{
    padding: 1px 0;
    text-align: center;
    max-width: 228px;
    margin: auto;

    &.box-end-button-top-gutter{
        max-width: 265px;
        padding-top: 20px;

        .button{
            margin: 0;
        }
    }
}

.go-back-btn{
    max-width: 92px;
    margin: 0 0 0 auto;

    .button{
        padding-right: 1.4em;
        padding-left: 1.4em;
    }
}

.box-white-padr-closest{
    margin: 0 0 10px;
}
